import * as logger from '~/utils/logging'
import type { CardData } from '../types'

export const getCardAt = <T extends any = CardData>(
  cards: T[],
  idx: number,
) => {
  if (!cards[idx]) {
    logger.warn(`Card at index ${idx} not found in cards array`, cards)
    return
  }
  return cards[idx] as T
}
