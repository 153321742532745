<template>
  <template v-for="(chunk, row) in cluster(index, columns)" :key="row">
    <div
      class="lyt-grid"
      :class="{
        ...classes,
        [`lyt-grid-cols-${columns}`]: true,
        [`lyt-grid-row-${row + 1}`]: true,
      }"
      :data-cols="columns"
    >
      <template v-for="idx in chunk" :key="idx">
        <div
          class="lyt-grid-item"
          :class="{
            [`idx-${idx}`]: true,
          }"
        >
          <slot name="item" :idx="idx" :row="row" />
        </div>
      </template>
    </div>
  </template>
</template>

<script setup lang="ts">
import { cluster, range } from 'radash'

const slots = defineSlots()

const props = defineProps<{
  length: number
  columns: number
}>()

const attrs = useAttrs()
const classes = computed(() => {
  const cls = (attrs?.class as string) || ''
  const overrides = Object.fromEntries(
    cls
      .split(' ')
      .map((c) => c.trim())
      .map((c) => [c, true]),
  )

  return {
    [`lyt-grid-cols-${props.columns}`]: true,
    ...overrides,
  }
})

const index = computed(() => Array.from(range(props.length - 1)))
</script>
